.video-container {
    width: calc(100% - 30px) !important;
    height: 80% !important;
}

.OT_edge-bar-item {
    display: none !important;
}

.mainContainerDiv {
    height: 100% !important;
    text-align: center !important;
}

.videoPausedCount, .appInBackgroundCount {
    width: 50% !important;
    float: left !important;
}

.exam-title {
    line-height: 18px !important
}

.student-full-name {
    font-weight: bolder;
    color: black !important;
    font-size: 17px;
    margin-top: 10px;
}

.font-style-bold {
    font-weight: bold !important;
    color: black !important;
}

.padding-left {
    padding-left: 5px !important;
}

.pause-exam {
    margin-left: 10px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.OT_publisher {
    display: none;
    height: 0 !important;
}

.OT_subscriber {
    position: absolute;
    width: calc(100% - 30px) !important;
    height: 80% !important;
}
