.consult-content {
    background: #ffffff;
    height: calc(100% - 48px) !important;
    margin-top: 48px;
}

.consult-title {
    text-transform: capitalize !important;
    font-size: 16px !important;
    letter-spacing: 0 !important;
    color: #989898 !important;
    opacity: 1 !important;
}

.end-consult-button {
    right: 10px !important;
    position: absolute !important;
    background: red !important;
}

.suspend-consult-button {
    right: 90px !important;
    position: absolute !important;
    background: #ffc257 !important;
}

.moreButton {
    margin-left: 5px !important;
    margin-right: 5px !important;
    font-weight: bold !important;
}

.moreButton:hover {
    cursor: pointer;
    font-size: 17px;
    margin-right: 2px !important;
}

.fullHeight {
    height: calc(100vh - 48px) !important;
}

.height50 {
    height: calc(50vh - 24px) !important;
}

.height33 {
    height: calc(33vh - 16px) !important;
}

.exam-app-header {
    font-weight: 400 !important;
    color: black !important;
}

.stats-label {
    color: #727272 !important;
    font-size: 14px;
}

.stats-value {
    color: #333 !important;
    font-size: 14px;
}

.value-font {
    font-weight: 400 !important;
    color: #00000091 !important;
}

.exam-video-container {
    padding: 15px;
    height: 100% !important;
}
