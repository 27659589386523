.blue-blink-dot {
    height: 10px !important;
    width: 10px !important;
    margin-right: 5px !important;
    border-radius: 50% !important;
    background-color: #28a745 !important;
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}
