.blue-blink-dot {
    height: 10px !important;
    width: 10px !important;
    margin-right: 5px !important;
    border-radius: 50% !important;
    background-color: #28a745 !important;
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.tabPanel .MuiPaper-root.MuiPaper-rounded {
    position: inherit !important;
}

.tabPanel .MuiPaper-root.MuiPaper-rounded div:first-child {
    position: inherit !important;
}

.tabPanel .MuiPaper-root.MuiAlert-root div:first-child {
    position: initial !important;
}

/* .MuiAlert-root{

} */
