.inputFile {
    display: none !important;
}

.MuiAlert-icon {
    position: unset !important;
}

.upload-recording-btn {
    width: 150px !important;
}
