
.page-content {
    background: white;
    margin-top: 50px !important;
    margin-bottom: 35px !important;
}


.ReactTable .-previous .-btn {
    background: #1890ff !important;
    color: white !important;
}

.ReactTable .-previous .-btn:disabled {
    background: rgba(0, 0, 0, 0.1) !important;
    color: black !important;
}

.ReactTable .-next .-btn {
    background: #1890ff !important;
    color: white !important;
}

.ReactTable .-next .-btn:disabled {
    background: rgba(0, 0, 0, 0.1) !important;
    color: black !important;
}

.rt-td {
    white-space: normal !important
}

.ReactTable .rt-thead .rt-th.-sort-asc {
    box-shadow: none !important;
}

.ReactTable .rt-thead .rt-th.-sort-desc {
    box-shadow: none !important;
}

.ReactTable .rt-thead .rt-th.-sort-asc div:after {
    content: "\25bc";
    font-size: .7em;
    padding-left: 7px;
    vertical-align: middle;
    box-shadow: none !important;
}

.ReactTable .rt-thead .rt-th.-sort-desc div:after {
    content: "\25B2";
    font-size: .7em;
    padding-left: 7px;
    vertical-align: middle;
    box-shadow: none !important;
}

.mainContainer {
    display: flex !important;
    height: 100% !important;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
}

.logout-icon {
    position: absolute;
    right: 0 !important;
}

.header-name {
    font-size: 20px;
    font-weight: bold;
}
