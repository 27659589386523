body {
    background-color: #fff !important
}

.login {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    margin-left: 1px !important;
    font-size: 14px !important;
}

.login span:hover {
    color: red !important;
    cursor: pointer !important;
}
