.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




.MuiToolbar-regular {
  min-height: 48px !important;
}

.MuiTableCell-root{
  padding: 8px 16px !important;
}

.table-header-title {
  font-size: 21px;
  letter-spacing: 0;
  color: #A2A2A2;
  opacity: 1;
  padding-left: 12px;
}

.table-header-title-container {
  padding: 10px 0 !important;
  width: 100% !important;
}

.custom-table-container {
  padding: 0 !important;
}

.custom-table-container .MuiToolbar-gutters {
  padding-left: 0 !important;
}

div[class^="MTableToolbar-title"] {
  width: 100% !important;
}

.MuiToolbar-root div[class^="MTableToolbar-spacer-"] {
  flex: 1 1 0% !important;
}

.marginTop10{
  margin-top: 10px !important
}

.react-tel-input {
  margin-top: 10px !important;
}

.react-tel-input .form-control{
  width: 100% !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
  border-radius: 0 !important;
}

.react-tel-input .form-control+div:before {
  content: 'Phone *' !important;
  text-align: left;
  font-size: 11px !important;
  width: 49px !important;
  margin-left: -30px;
  color: #515151 !important;
  /* margin-top: 10px; */
}

.react-tel-input .form-control {
  padding-bottom: 10px !important;
  padding-top: 10px !important;
  font-size: 14px !important;
  /* margin-top: 10px; */
}

.setGridFieldPadding{
  padding: 12px 12px 0px 12px !important;
}

.MuiCardHeader-root{
  padding: 16px 16px 0px 16px !important;
}

/****************************************/

.App-container {
  display: flex;
  width: 100%;
}

.App-section-publisher {
  width: 400px;
}

.App-section-subscribers {
  width: 100%;
}

.OTSubscriberContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  place-items: center;
  min-height: calc(80% - 1px) !important;
}

.OTSubscriberContainer video {
  object-fit: contain !important;
}
